<script setup lang="ts">
import { ResultsProps } from "./Results.props"
import { formatMoney, formattedPricePerUnit } from "~/utils/formatters"
import { PRODUCT_CLICKED } from "~/config/algolia-insights"
import {
  algoliaEventClickProductAfterSearch,
  algoliaEventClickProduct
} from "~/utils/algolia"
import { algoliaSearch } from "~/config/algolia"
import { fallbackImage, addToCartPosition } from "~/utils/constants"
import type { GA4Entities } from "nuxt-ga4"
import { ProductCardButtonsProps } from "~/components/Utils/ProductCardButtons.props"
import { isGreaterThan } from "~/utils/productPrice"
import { productUtils } from "~/utils/product"
import { SaveForLaterProductForAction } from "~/composables/useSavedForLater"
import { getSkuItemMetadata } from "~/utils/savedForLater/savedForLater"

const props = defineProps<ResultsProps>()

const emit =
  defineEmits<{
    (e: "onNotifyMe"): void
    (e: "onOpenNotifyMeModal"): void
  }>()

const { addToCart } = useCart()
const skuState = useState("product-unavailable-state", () => "")
const isAddingToCart = ref(false)
const disableLink = ref(false)

const clickProductEvent = () => {
  const algolia = algoliaSearch()
  localStorage.setItem(
    "algolia-last-clicked-product",
    `{"queryID": "${props?.queryID ?? ""}","path": "${props?.path ?? ""}"}`
  )
  if (props.queryID) {
    algoliaEventClickProductAfterSearch(
      algolia.indices.products,
      `${PRODUCT_CLICKED}`,
      [`product_${props.productCode}`],
      props.queryID,
      [props.position ?? 1]
    )
  } else {
    algoliaEventClickProduct(algolia.indices.products, `${PRODUCT_CLICKED}`, [
      `product_${props.productCode}`
    ])
  }
}

const handleAddToCart = (quantity: number) => {
  disableLink.value = true
  useTimeoutFn(() => (disableLink.value = false), 2000)

  const { sendEventAddToCart } = useGA4SiteEvents()
  isAddingToCart.value = true

  useTimeoutFn(() => {
    isAddingToCart.value = false
  }, 2000)

  addToCart(
    {
      sku_code: props.productCode,
      metadata: {
        product_image: props?.productImage!,
        slug: props.path,
        price: props.currentPrice,
        oldPrice: props.oldPrice,
        inStock: props.inStock,
        expirationDate: props?.expirationDate ?? "",
        unitType: props?.unitType ?? "",
        isDeductable: props?.isDeductable,
        ga4Item: statefulEntityItem?.value ?? {}
      }
    },
    quantity
  )

  const position = Object.keys(statefulEntityItem?.value ?? {}).length
    ? addToCartPosition.listing
    : addToCartPosition.orderAgain

  sendEventAddToCart(statefulEntityItem?.value ?? {}, {
    currentPrice: props.currentPrice?.toString() ?? "",
    position: position
  })
}

const handleNotifyMe = () => {
  disableLink.value = true
  useTimeoutFn(() => (disableLink.value = false), 2000)

  skuState.value = props?.productCode ?? ""

  const { event, sendEvent } = useGAEvent(
    `track_product_availability_warning_${props.productCode ?? ""}_`,
    "custom_event"
  )

  if (Object.keys(statefulEntityItem?.value ?? {}).length) {
    event.value = {
      event: "custom_event",
      action: "product_availability_warning",
      items: [statefulEntityItem?.value ?? {}]
    }

    sendEvent({ clearEcommerce: false })
  }
  emit("onNotifyMe")
}

/** Get single GA4 item */
const statefulEntityItem: GA4Entities["gaItem"] | undefined =
  inject("statefulEntityItem")

const product = computed((): SaveForLaterProductForAction => {
  return {
    productId: props?.productCode ?? "",
    skuId: "",
    metadata: getSkuItemMetadata(
      props?.productImage,
      props?.slug ?? "",
      props.currentPrice,
      props.oldPrice,
      props.inStock ? 1 : 0,
      props.unitType,
      props.expirationDate,
      props.isDeductable
    )
  }
})
const productCardButton = computed((): ProductCardButtonsProps => {
  return {
    inStock: props.inStock,
    sellabilityStatus: props.sellabilityStatus,
    showQuantity: props.showQuantity,
    userLogged: props.userLogged,
    queryID: props.queryID,
    position: props.position,
    productCode: props.productCode,
    path: props.path,
    product: product.value
  }
})

const preventNavigation = () => {
  disableLink.value = true
  useTimeoutFn(() => (disableLink.value = false), 2000)
}
</script>

<template>
  <div
    class="
      @container
      relative
      block
      w-[242px]
      transform-gpu
      rounded-lg
      bg-white
      px-4
      py-2
      drop-shadow-01
      transition
      hover:drop-shadow-02
      md:w-auto
    "
  >
    <GAItem :id="props?.productCode ?? ''" :item="[props]">
      <GASelectItem class="h-full">
        <div class="flex h-full w-full flex-col justify-between gap-2">
          <div class="flex h-full" :class="{ 'md:flex-col': listingLayout }">
            <div
              class="relative hidden"
              :class="{ ' md:block': listingLayout }"
            >
              <div class="absolute left-0 top-0 z-40 mb-2 flex flex-wrap gap-2">
                <template
                  v-if="
                    inStock || productUtils.isSellableStatus(sellabilityStatus)
                  "
                >
                  <BadgeProduct
                    v-for="promo in promotions"
                    :key="`promo-${promo.theme}`"
                    v-bind="promo"
                  />
                </template>
                <span
                  class="
                    badge-expiry
                    snail-bold
                    md:mouse-bold
                    flex
                    items-center
                    whitespace-nowrap
                    rounded
                    bg-orange-10
                    p-1
                    text-black-main
                    md:px-2
                    lg:py-[2px]
                  "
                  v-else
                >
                  <UtilsIcon name="Warning.svg" class="mr-1 h-6 w-6" />
                  {{ $t("productTile.overall.notSellable") }}
                </span>
              </div>
            </div>
            <div
              class="product-image-container mb-2"
              :class="{
                'min-h-0 md:flex-1': isTallCard,
                ' md:w-full md:flex-1': listingLayout,
                'w-2/5 flex-none': !isTallCard
              }"
            >
              <AppLink
                :isDisabled="disableLink"
                :to="path"
                @click="clickProductEvent"
              >
                <UtilsWrapperImage
                  v-if="productImage"
                  v-bind="productImage"
                  sizes="200px md:200px lg:250px xl:250px"
                  class="wrapper-image-fit-contain"
                  :class="{
                    'wrapper-image-fixed-184 ': !isTallCard
                  }"
                />
                <UtilsWrapperImage
                  v-else
                  v-bind="fallbackImage"
                  :class="{
                    'wrapper-image-fixed-184 ': !isTallCard
                  }"
                />
              </AppLink>
            </div>

            <div
              class="content-container ml-2 break-all"
              :class="{
                'flex h-full flex-col md:justify-between': listingLayout,
                'md:h-auto md:flex-none md:justify-evenly': isTallCard
              }"
            >
              <div class="top-content">
                <div
                  class="badge-container-mobile z-10 mb-2 flex flex-wrap gap-2"
                  :class="{ ' md:hidden': listingLayout }"
                >
                  <template
                    v-if="
                      inStock ||
                      productUtils.isSellableStatus(sellabilityStatus)
                    "
                  >
                    <BadgeProduct
                      v-for="promo in promotions"
                      :key="`promo-${promo.theme}`"
                      v-bind="promo"
                    />
                  </template>
                  <span
                    class="
                      badge-expiry
                      snail-bold
                      md:mouse-bold
                      flex
                      items-center
                      whitespace-nowrap
                      rounded
                      bg-orange-10
                      p-1
                      text-black-main
                      md:px-2
                      lg:py-[2px]
                    "
                    v-else
                  >
                    <UtilsIcon name="Warning.svg" class="mr-1 h-6 w-6" />
                    {{ $t("productTile.overall.notSellable") }}
                  </span>
                </div>
                <p
                  class="title beaver-bold mb-2 line-clamp-3 break-normal"
                  :class="{ 'md:dolphin-bold': isTallCard }"
                >
                  <AppLink
                    :isDisabled="disableLink"
                    :to="path"
                    @click="clickProductEvent"
                  >
                    {{ title }}
                  </AppLink>
                </p>
              </div>
              <div
                v-if="
                  rating &&
                  reviews &&
                  rating > 0 &&
                  reviews > 0 &&
                  (inStock || productUtils.isSellableStatus(sellabilityStatus))
                "
                class="rating-container mb-2 flex items-center gap-1"
              >
                <UtilsStars :stars="rating" />
                <span class="mouse">{{ reviews }}</span>
              </div>
              <div v-if="inStock" class="price-container mb-2">
                <div class="@[258px]:flex-nowrap flex flex-wrap items-center">
                  <span
                    v-if="currentPrice"
                    class="discount-price elephant-bold text-black-800"
                  >
                    {{ formatMoney(currentPrice) }}
                  </span>
                  <span
                    v-if="isGreaterThan(oldPrice, currentPrice)"
                    class="full-price beaver ml-2 text-black-80 line-through"
                  >
                    {{ formatMoney(oldPrice) }}
                  </span>
                </div>
                <template
                  v-if="
                    productUtils.isDisplayablePricePerUnit(
                      pricePerUnit,
                      unitType
                    )
                  "
                >
                  <span class="unit-per-price mouse text-black-80">
                    {{
                      formattedPricePerUnit(formatMoney(pricePerUnit), unitType)
                    }}
                  </span>
                </template>
              </div>
              <div
                v-else-if="productUtils.isSellableStatus(sellabilityStatus)"
                class="available-container pb-2"
              >
                <span class="mouse text-negative-main">
                  {{
                    availableIn
                      ? `${$t(
                          "productTile.overall.availableIn"
                        )} ${availableIn} ${$t(
                          availableIn > 1
                            ? "productTile.overall.days"
                            : "productTile.overall.day"
                        )}`
                      : $t("productTile.overall.notAvailable")
                  }}
                </span>
              </div>
              <div v-else>
                <UtilsMarkdown
                  class="
                    beaver
                    markdown__strong-beaver-bold
                    w-full
                    break-normal
                  "
                  :key="'notSellableText'"
                  :class="'mb-4'"
                  :content="
                    $t('productPage.purchaseType.notSellable.subtitle_full')
                  "
                />
              </div>
            </div>
          </div>

          <div clasS="h-max">
            <UtilsProductCardButtons
              v-if="currentPrice && currentPrice > 0"
              @on-add-to-cart="handleAddToCart($event)"
              @on-notify-me="handleNotifyMe"
              @on-open-notify-me-modal="$emit('onOpenNotifyMeModal')"
              @click="preventNavigation"
              v-bind="productCardButton"
              :class="{ 'md:flex-none': isTallCard }"
            />
          </div>
        </div>
      </GASelectItem>
    </GAItem>
  </div>
</template>
